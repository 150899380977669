import React, { useRef } from "react";
import placeholderIcon from "../../../assets/icons/photo-placeholder.svg";
import chainIcon from "../../../assets/icons/chain.svg";
import nftIcon from "../../../assets/icons/nft.svg";
import buyIcon from "../../../assets/icons/buy.svg";
import EditIcon from "../../../assets/icons/edit.svg?react";
import { GradientCard } from "../../ui/GradientCard";
import Button from "../../ui/Button";

interface TokenImageNFTChooseProps {
  selectedTokenImage: null | File;
  setSelectedTokenImage: (image: File | null) => void;
}

const TokenImageNFTChoose: React.FC<TokenImageNFTChooseProps> = React.memo(
  ({ selectedTokenImage, setSelectedTokenImage }) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleButtonClick = () => {
      fileInputRef.current?.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) setSelectedTokenImage(file);
    };

    return (
      <div className="relative flex items-center gap-6 pb-[22px] justify-center ">
        <div className="flex flex-col items-center gap-[14px]">
          <p className={`text-[12px] font-medium text-paleGrey ${selectedTokenImage ? "invisible" : ""}`}>
            Choose token image
          </p>
          <div className="size-[88px] flex justify-center items-center rounded-2xl bg-[#3a2e3d]">
            {selectedTokenImage ? (
              <img
                src={URL.createObjectURL(selectedTokenImage)}
                alt="Selected photo"
                className="rounded-2xl size-full object-cover"
              />
            ) : (
              <img src={placeholderIcon} alt=" placeholder" className="rounded-2xl size-6 object-cover" />
            )}
          </div>
          <div className="pb-[5px]">
            <GradientCard backgroundColor="#242026">
              <button
                type="button"
                onClick={handleButtonClick}
                className={`py-[6px] px-5 flex gap-[6px] rounded-3xl ${selectedTokenImage ? "text-white" : "text-[#aa9db2]"}  leading-4 text-[12px] font-semibold `}
              >
                <EditIcon className={`size-4 ${selectedTokenImage ? "fill-white" : "fill-[#aa9db2]"}`} />
                <span>Edit</span>
              </button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="hidden"
                accept="image/png, image/jpeg"
              />
            </GradientCard>
          </div>
        </div>
        <img src={chainIcon} alt="chain" className="size-6" />
        <div className="flex flex-col items-center gap-[14px]">
          <p className="text-[12px] font-medium text-paleGrey">Choose NFT</p>
          <div className="size-[88px] flex justify-center items-center rounded-full bg-[#3a2e3d]">
            <img src={nftIcon} alt=" placeholder" className="rounded-2xl size-6 object-cover" />
          </div>
          <div className="relative w-[96px] h-[33px] flex justify-center">
            <Button
              text="Buy"
              className="rounded-[32px] !w-[88px] text-[12px] h-[28px] px-3 z-40 !py-3"
              classNameIcon="w-[14px] h-[14px] mr-1"
              icon={buyIcon}
            />
            <div
              className="absolute top-1 w-full  h-[38px] rounded-2xl z-10"
              style={{
                backgroundImage: " linear-gradient(150deg, #ffae34 0%, #f37e3b 100%)",
                filter: "blur(40px)",
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  },
);

export default TokenImageNFTChoose;
