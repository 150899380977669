export enum TokensBuyStatus {
  CREATED = "Created",
  BUEYD = "Buyed",
}

export interface Token {
  id: number;
  logo: string;
  name: string;
  ticker: string;
  createdBy: number;
  userName: string;
  progress: string;
  marcetCap: string;
  liquidity: string;
  description: string;
  tgChatLink: string;
  tgChannelLink: string;
  xTwitter: string;
  status: TokensBuyStatus;
  createdAt: string;
  updatedAt: string;
  mintAddress: string | null;
  mintAccount: string | null;
  totalSupply: string | null;
  poolWallet: string | null;
  notesCount: number;
}

export interface TokenInfo extends Token {
  transactions: Transaction[];
  user: {
    id: number;
    photo_url: string | null;
  };
}

export interface TokenPriceData {
  priceWithoutFee: number;
  fee: number;
  priceWithFee: number;
}
export interface AssociatedAccounts {
  tokenCount: number;
  tokenAccount: string;
  sol: number;
}

export interface TokenData {
  token: TokenInfo;
  tokenPriceData: TokenPriceData;
  associatedAccounts: AssociatedAccounts;
}
export interface CurrentCountTokensAndPrice {
  sumForPay: number;
  tokensToRelease: number;
}
export interface CurrentCountTokensForSell {
  totalTokens: number;
}

export interface CreateToken {
  logo: string | File | null;
  name: string;
  ticker: string;
  description: string;
  tgChatLink: string;
  tgChannelLink: string;
  xTwitter: string;
}

export enum TransactionsStatus {
  SELL = "Sell",
  BUY = "Buy",
  DEPLOY = "Deploy",
}

export interface TransactionToken {
  logo: string;
  name: string;
  ticker: string;
  userName: string;
}

export interface Transaction {
  id: number;
  userId: number;
  tokenId: number;
  status: TransactionsStatus;
  coinQuantity: number | string;
  createdAt: string;
  updatedAt: string;

  token?: TransactionToken;
  user?: {
    first_name: string;
  };
}

export interface Note {
  id: number;
  user?: {
    first_name: string;
    photo_url: string;
  };
  message: string;
  like: number;
  disLike: number;
  createdAt: string;
}

export enum FollowingStatus {
  FOLLOWING = "Following",
  UNFOLLOWING = "Unfollowing",
}
