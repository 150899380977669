import React from "react";
import useFocusHandler from "../../hooks/useFocusHandler";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string | number;
  label?: string | number;
  border?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputWidthLabel: React.FC<InputProps> = React.memo(
  ({ value, className = "", border = false, handleChange, label, ...attrs }) => {
    const paddingBottom = window.visualViewport ? window.innerHeight - window.visualViewport.height : 0;
    let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    const { inputRef, handleFocus, handleBlur } = useFocusHandler<HTMLInputElement>(
      paddingBottom + window.innerHeight / 2,
      isIOS,
    );

    return (
      <div ref={inputRef} className={`relative ${border ? "border-solid border-b-[1px]  border-[#fff]/10" : ""}`}>
        <input
          type="text"
          value={value}
          {...attrs}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className="peer m-0 block h-[58px] w-full rounded  bg-transparent bg-clip-padding py-4 text-sm font-semibold leading-tight text-white transition duration-200 ease-linear placeholder:text-transparent focus:border-primary focus:pb-[0.625rem] focus:pt-[1.625rem]  focus:outline-none peer-focus:text-primary   [&:not(:placeholder-shown)]:pb-[0.625rem] [&:not(:placeholder-shown)]:pt-[1.625rem]"
          placeholder=""
        />
        <label className="pointer-events-none absolute left-0 top-0 origin-[0_0] border border-solid border-transparent py-4 text-base text-white/50 transition-[opacity,_transform] duration-200 ease-linear peer-focus:-translate-y-2  peer-focus:text-[12px] peer-focus:text-white/50 peer-[:not(:placeholder-shown)]:-translate-y-2  peer-[:not(:placeholder-shown)]:text-[12px] motion-reduce:transition-none ">
          {label}
        </label>
      </div>
    );
  },
);

export default InputWidthLabel;
