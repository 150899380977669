import { useSelector } from "react-redux";
import { moneyFormat, nFormatter } from "../../../../helpers/moneyFormat";
import { TradeType } from "./FilterTrade";
import { currentTokenSelector } from "../../../../store/token/tokensReducer";

const TradeInfo = ({}: { activeTradeType: TradeType }) => {
  return (
    <>
      {" "}
      <SellInfo />{" "}
    </>
  );
};

// const BuyInfo = () => {
//   const token = useSelector(currentTokenSelector);

//   return (
//     <div>
//       <div className="flex gap-2 w-full">
//         <Block
//           value={moneyFormat(1000000000)}
//           text="Max Supply"
//           className="rounded-2xl bg-black/25 px-4 py-[14px] w-full "
//         />
//         <Block
//           value={moneyFormat(+token?.totalSupply!)}
//           text="Available to buy"
//           className="rounded-2xl bg-black/25 px-4 py-[14px] w-full "
//         />
//       </div>
//     </div>
//   );
// };

const SellInfo = () => {
  const token = useSelector(currentTokenSelector);

  return (
    <div>
      <div className=" w-full rounded-2xl bg-black/25 uppercase px-3 py-3">
        <div className="flex justify-between pb-[14px]  border-solid border-b-[2px] border-white/10">
          <Block
            value={moneyFormat(1000000000)}
            text="Max Supply"
            className="w-1/2 border-solid border-r-[2px] border-white/10"
          />
          <Block value={moneyFormat(+token?.totalSupply!)} text="Available to buy" className="w-1/2 pl-3" />
        </div>
        <div className="flex justify-between pt-[14px]">
          <Block
            value={nFormatter(+(token?.marcetCap || 0))}
            text="MKT KAP"
            className="border-solid border-r-[2px] border-white/10 pr-[5vw]"
          />
          <Block
            value={nFormatter(+(token?.liquidity || 0), 6)}
            text="Liquidity"
            className="border-solid border-r-[2px] border-white/10 pr-[5vw] pl-[5vw]"
          />
          <Block value="521/1000" text="TON Collected" className="pl-[5vw] " />
        </div>
      </div>
    </div>
  );
};

const Block = ({ text, value, className }: { text: string; value: string | number; className?: string }) => {
  return (
    <div className={`flex flex-col gap-[14px]    leading-none  ${className}`}>
      <p className="font-semibold text-lavenderGray">{text}</p>
      <p className="font-bold">{value}</p>
    </div>
  );
};

export default TradeInfo;
