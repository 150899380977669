import { GradientCard } from "../ui/GradientCard";

import chevronRightIcon from "../../assets/icons/chevron-right.svg";
import { redirectToWalletManagement } from "../../helpers/redirectToWalletManagement";
import { FC } from "react";
import { moneyFormatWithTwoDigits } from "../../helpers/moneyFormat";

interface UserWalletInfoProprs {
  showBalance?: boolean;
  abilitytoRedirectToWalletManagement?: boolean;
  phantomWalletPublicKey?: string;
  phantomWalletBalance?: string | number;
  isActiveWallet?: boolean;
  userName?: string;
  userId?: string | number;
}

const UserWalletInfo: FC<UserWalletInfoProprs> = ({
  showBalance = false,
  abilitytoRedirectToWalletManagement = false,
  phantomWalletPublicKey,
  isActiveWallet = false,
  phantomWalletBalance,
  userId,
  userName,
}) => {
  const handler = () => {
    if (abilitytoRedirectToWalletManagement) redirectToWalletManagement();
  };
  console.log("phantomWalletBalance", phantomWalletBalance);

  return (
    <div className="w-full">
      <GradientCard backgroundColor="#28202C">
        <div className="px-4" onClick={handler}>
          <div className={` ${isActiveWallet ? "border-solid border-b-[1px]  border-[#fff]/10 pb-3" : ""} py-3`}>
            <p className="text-[12px] text-white/50">{showBalance ? "Balance" : "Username"}</p>
            <p className="font-semibold">
              {showBalance && phantomWalletBalance
                ? `${moneyFormatWithTwoDigits(+phantomWalletBalance)} SOL`
                : `@${userName ? userName : `user${userId}`}`}
            </p>
          </div>
          {isActiveWallet && (
            <div className="flex justify-between items-center py-3">
              <div>
                <p className="text-[12px] text-white/50">Connected Wallet</p>
                <p className="font-semibold">{shortenKey(phantomWalletPublicKey!)}</p>
              </div>
              {abilitytoRedirectToWalletManagement && (
                <div className="block mr-1">
                  <img src={chevronRightIcon} alt="Right arrow" />
                </div>
              )}
            </div>
          )}
        </div>
      </GradientCard>
    </div>
  );
};

export default UserWalletInfo;

function shortenKey(key: string): string {
  if (key.length <= 10) return key;
  const start = key.slice(0, 6);
  const end = key.slice(-6);
  return `${start}...${end}`;
}
