import { useEffect, useState } from "react";
import LastTransaction from "../../components/Pumpadur/Main/LastTransaction";
import TokenList from "../../components/Pumpadur/Main/TokenList";
import TopTokenBanner from "../../components/Pumpadur/Main/TopTokenBanner";
import UserProfileHeader from "../../components/Pumpadur/UserProfileHeader";
import { useAppDispatch } from "../../store/hooks";
import { Token } from "../../store/token/api/types";
import { fetchHotTokensListAction } from "../../store/token/actions/fetchHotTokensListAction";
import { getTopCapToken } from "../../api/tokens/getTopCapToken";

const MainPage = () => {
  const [capTopToken, setCapTopToken] = useState<Token | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchHotTokensListAction());

    getTopCapToken().then((data) => {
      setCapTopToken(data);
    });
  }, []);

  return (
    <div>
      <UserProfileHeader />
      <div className="pt-4">
        <LastTransaction />
      </div>
      <div className="pt-2">
        <TopTokenBanner token={capTopToken} />
      </div>
      <TokenList />
    </div>
  );
};

export default MainPage;
