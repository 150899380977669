import { getTimeDifference } from "../../../../helpers/getTimeDifference";
import { moneyFormatWithTwoDigits } from "../../../../helpers/moneyFormat";
import { Transaction, TransactionsStatus } from "../../../../store/token/api/types";
import { GradientCard } from "../../../ui/GradientCard";
import TokenImage from "../../Token/TokenImage";

const TransactionItem = ({ transaction }: { transaction: Transaction }) => {
  const { coinQuantity, createdAt, status, token } = transaction;
  const { name, ticker, logo } = token!;

  const colorForStatusTransaction = forStatusTransaction(status);
  return (
    <GradientCard backgroundColor="#28202C">
      <div className="flex p-3 justify-between items-center font-semibold leading-none">
        <div className="flex gap-3 justify-between items-center">
          <div className="rounded-2xl bg-white w-[48px] h-[48px] min-w-[48px] min-h-[48px] ">
            <TokenImage imageSrc={logo} classesName="size-full object-cover rounded-2xl" />
          </div>
          <div className="flex flex-col gap-[11px]">
            <p>{name}</p>
            <div className="flex gap-2 text-[12px]">
              <p className={`font-bold ${colorForStatusTransaction}`}>{status}</p>
              <p className="font-medium text-paleGrey">{getTimeDifference(createdAt)}</p>
            </div>
          </div>
        </div>
        <div className="tex-[12px] text-paleGrey uppercase">${ticker}</div>
        <div className="flex flex-col gap-[13px]">
          <p>{moneyFormatWithTwoDigits(+coinQuantity)} SOL</p>
          <div className="text-[10px] text-paleGrey">
            <span>8.7M </span>
            <span className="uppercase">${ticker} </span>
          </div>
        </div>
      </div>
    </GradientCard>
  );
};

export function forStatusTransaction(status: TransactionsStatus) {
  switch (status) {
    case TransactionsStatus.BUY: {
      return "text-green";
    }
    case TransactionsStatus.DEPLOY: {
      return "text-lightBlue";
    }
    case TransactionsStatus.SELL: {
      return "text-hotPink";
    }
  }
}

export default TransactionItem;
