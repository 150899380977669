import { useSelector } from "react-redux";
import { BottomSheet } from "../../../ui/BottomSheet";
import Button from "../../../ui/Button";
import {
  currentCountTokensAndPriceSelector,
  currentCountTokensForSellSelector,
  currentTokenPriceDataSelector,
  currentTokenSelector,
} from "../../../../store/token/tokensReducer";
import { ExternalImage } from "../../../ui/ExternalImage";
import { useTransaction } from "../../../../hooks/useTransaction";
import { useAppDispatch } from "../../../../store/hooks";
import { useCallback, useEffect, useState } from "react";
import { fetchCountTokensAndPriceAction } from "../../../../store/token/actions/fetchCountTokensAndPriceAction";
import { saveDataForTransactionAction } from "../../../../store/token/actions/saveDataForTransactionAction";
import { TradeType } from "./FilterTrade";
import { fetchCountTokensForSellAction } from "../../../../store/token/actions/fetchCountTokensForSellAction";

interface ConfirmPurchaseProps {
  isOpened: boolean;
  setIsOpened: (value: boolean) => void;
  solanaAmount: number;
  activeTradeType: TradeType;
}

const ConfirmPurchase = ({ isOpened, setIsOpened, solanaAmount, activeTradeType }: ConfirmPurchaseProps) => {
  const [isPending, setIsPending] = useState(false);

  const token = useSelector(currentTokenSelector);
  const currentTokenPriceData = useSelector(currentTokenPriceDataSelector);
  const currentCountTokensAndPrice = useSelector(currentCountTokensAndPriceSelector);
  const currentCountTokensForSell = useSelector(currentCountTokensForSellSelector);

  const { signAndSendTransaction, sellTokenTransaction } = useTransaction();

  const { name, ticker, logo } = token!;

  const messageSend = activeTradeType === TradeType.BUY ? `Send SOL` : `Send $${ticker}`;
  const valueSend =
    activeTradeType === TradeType.BUY
      ? currentCountTokensAndPrice?.sumForPay || 0
      : currentCountTokensForSell?.totalTokens || 0;
  const messageReceive = activeTradeType === TradeType.BUY ? `Receive ${ticker}` : `Receive SOL`;
  const valueReceive =
    activeTradeType === TradeType.BUY ? currentCountTokensAndPrice?.tokensToRelease || 0 : solanaAmount || 0;

  const dispatch = useAppDispatch();

  const getActualCountToken = useCallback(
    (value: number | null = null) => {
      const amount = value ? value : +solanaAmount;
      const data = {
        priceWithFee: currentTokenPriceData?.priceWithFee || 0,
        priceWithoutFee: currentTokenPriceData?.priceWithoutFee || 0,
        amount: amount || 0,
      };
      console.log("activeTradeType", activeTradeType);

      if (activeTradeType === TradeType.BUY) {
        dispatch(fetchCountTokensAndPriceAction(data));
      }
      if (activeTradeType === TradeType.SELL) {
        dispatch(fetchCountTokensForSellAction(data));
      }
    },
    [solanaAmount, currentTokenPriceData, activeTradeType],
  );

  const saveTransactionData = async () => {
    const tokensToRelease =
      activeTradeType === TradeType.BUY
        ? currentCountTokensAndPrice?.tokensToRelease
        : currentCountTokensForSell?.totalTokens;
    const saveDataTransaction = {
      amount: solanaAmount!,
      mintAddress: token?.mintAddress!,
      tokenId: token?.id!,
      tokenAccount: token?.mintAccount!,
      tokensToRelease: tokensToRelease!,
    };
    await dispatch(saveDataForTransactionAction(saveDataTransaction));
  };

  const buyToken = async () => {
    getActualCountToken();

    saveTransactionData();
    // setIsOpened(false);
    setTimeout(async () => {
      console.log("Transaction signed and sent");
      try {
        await signAndSendTransaction();
        console.log("Transaction signed and sent");
      } catch (err) {
        console.error("Error signing and sending transaction:", err);
      }
      setIsPending(false);
    }, 2000);
  };

  const sellToken = async () => {
    getActualCountToken();

    saveTransactionData();
    setTimeout(async () => {
      console.log("Transaction signed and sent");
      try {
        await sellTokenTransaction();
        console.log("Transaction signed and sent");
      } catch (err) {
        console.error("Error signing and sending transaction:", err);
      }
      setIsPending(false);
    }, 2000);
  };

  const handleConfirm = async () => {
    setIsPending(true);
    if (activeTradeType === TradeType.BUY) buyToken();
    if (activeTradeType === TradeType.SELL) sellToken();
  };

  useEffect(() => {
    getActualCountToken();
  }, [isOpened]);
  return (
    <BottomSheet
      isOpened={isOpened}
      onClose={() => setIsOpened(false)}
      title="Confirm purchase"
      classNameForChildrenContainer="px-4 py-4"
    >
      <div className="mt-3 flex flex-col gap-2 text-[14px]">
        <div className="rounded-2xl bg-mediumGrey/25 px-2 pt-[10px] pb-4 ">
          <div className="flex justify-between px-2   border-solid border-b-[1px] border-white/10 pb-3">
            <p className=" font-semibold text-lavenderGray">Name</p>
            <div className="font-bold flex gap-2">
              <ExternalImage loading="lazy" src={logo} alt="token-image" className="size-5 object-cover" />
              <p>{name}</p>
            </div>
          </div>
          <div className="flex justify-between pt-3 px-2 ">
            <p className="font-semibold text-lavenderGray">Ticker</p>
            <div className="font-bold">
              <p>${ticker}</p>
            </div>
          </div>
        </div>

        <div className="rounded-2xl bg-mediumGrey/25 px-2 pt-[10px] pb-4 mb-[14px]">
          <div className="flex justify-between  px-2  border-solid border-b-[1px] border-white/10 pb-3">
            <p className=" font-semibold text-lavenderGray">{messageSend}</p>
            <div className=" font-bold flex gap-2 items-center">
              <img src="/solana-sol-logo.svg" className="size-4 object-cover" alt="solana-icon" />
              <p>{valueSend}</p>
            </div>
          </div>
          <div className="flex justify-between px-2  py-3 border-solid border-b-[1px] border-white/10">
            <p className=" font-semibold text-lavenderGray">{messageReceive}</p>
            <div className=" font-bold flex gap-2">
              <ExternalImage loading="lazy" src={logo} className="size-5 object-cover" alt="token-image" />{" "}
              <p>{valueReceive}</p>
            </div>
          </div>
          <div className="flex justify-between py-3 px-2  border-solid border-b-[1px] border-white/10">
            <p className="font-semibold text-lavenderGray">Share of the supply</p>
            <div className=" font-bold">
              <p>0.13%</p>
            </div>
          </div>
          <div className="flex justify-between pt-3 px-2 ">
            <p className=" font-semibold text-lavenderGray">Vesting period</p>
            <div className="font-bold">
              <p>22h 15m</p>
            </div>
          </div>
        </div>
        <p className="text-center pb-2 font-medium">
          <span className="pr-2 text-lavenderGray">Transaction fee:</span>
          <span>{currentTokenPriceData?.fee!} SOL</span>
        </p>
        <Button
          text="Confirm"
          isPending={isPending}
          handleClick={handleConfirm}
          style="linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"
        />
      </div>
    </BottomSheet>
  );
};

export default ConfirmPurchase;
