export function truncateDecimal(num: number, decimals: number = 6): number {
  const decimalCount = num.toString().split(".")[1]?.length || 0;

  // Якщо знаків після коми менше або рівно потрібній кількості, повертаємо число
  if (decimalCount <= decimals) {
    return num;
  }

  return parseFloat(num.toFixed(decimals));
}
