import { createAsyncThunk } from "@reduxjs/toolkit";
import { saveDataForTransaction } from "../../../api/transactions/saveDataForTransaction";
import { SaveTransactionData } from "../../../api/transactions/types";

export const saveDataForTransactionAction = createAsyncThunk(
  "tokens/saveDataForTransaction",
  async (data: SaveTransactionData) => {
    return saveDataForTransaction(data);
  },
);
