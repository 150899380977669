import { useCallback, useEffect, useState } from "react";
import SolanaAmountField from "./SolanaAmountField";
import Chip from "../../../ui/Chip";
import Button from "../../../ui/Button";
import { TradeType, setBackgroundTradeType } from "./FilterTrade";
import ConfirmPurchase from "./ConfirmPurchase";
import { useSelector } from "react-redux";
import {
  associatedAccountsSelector,
  currentCountTokensAndPriceSelector,
  currentCountTokensForSellSelector,
  currentTokenPriceDataSelector,
  currentTokenSelector,
} from "../../../../store/token/tokensReducer";
import { useAppDispatch } from "../../../../store/hooks";
import { fetchCountTokensAndPriceAction } from "../../../../store/token/actions/fetchCountTokensAndPriceAction";
import { debounce } from "lodash-es";
import {
  cryptoProfileBalanceSelector,
  isActiveWalletSelector,
} from "../../../../store/cryptoProfile/cryptoProfileReducer";
import { getDataSavedTransaction } from "../../../../api/transactions/getDataSavedTransaction";
import { useToastNotifications } from "../../../../hooks/useToastNotifications";
import { ToastNotificationType } from "../../../../store/toastNotifications/toastNotificationsReducer";
import { fetchCountTokensForSellAction } from "../../../../store/token/actions/fetchCountTokensForSellAction";

const AmountOption = ({
  amount,
  onClick,
  selectedAmountOption,
}: {
  amount: number;
  selectedAmountOption: number;
  onClick: (amount: number) => void;
}) => {
  const isActiveAmount = selectedAmountOption === amount;
  return (
    <div className="flex-shrink-0" onClick={() => onClick(amount)}>
      <Chip
        text={` ${amount} SOL`}
        bgColor={`${isActiveAmount ? "bg-white/30" : "bg-white/15"} `}
        textSize={`text-[14px] ${isActiveAmount ? "text-white" : "text-white/50"}`}
        paddingX="px-[17px]"
        height="h-[32px]"
      />
    </div>
  );
};

const TransactionControl = ({ activeTradeType }: { activeTradeType: TradeType }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isDisableTransaction, setIsDisableTransaction] = useState<boolean>(false);

  const amountOptions = [3, 50, 100, 200];

  const [solanaAmount, setSolanaAmount] = useState<number | string>(amountOptions[0]);
  const [selectedAmountOption, setSelectedAmountOption] = useState<number>(amountOptions[0]);
  const [message, setMessage] = useState<string>("");

  const dispatch = useAppDispatch();
  const { showNotification } = useToastNotifications();

  const currentTokenPriceData = useSelector(currentTokenPriceDataSelector);
  const currentCountTokensAndPrice = useSelector(currentCountTokensAndPriceSelector);
  const currentCountTokensForSell = useSelector(currentCountTokensForSellSelector);
  const token = useSelector(currentTokenSelector);
  const phantomWalletSolanaBalance = useSelector(cryptoProfileBalanceSelector);
  const associatedAccounts = useSelector(associatedAccountsSelector);
  const isActiveWallet = useSelector(isActiveWalletSelector);

  const debouncedInputChange = useCallback(
    debounce((value: number) => {
      getActualCountToken(value);
    }, 300),
    [activeTradeType],
  );
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    console.log("value", value);

    if (value === "") {
      setSolanaAmount("");
      return;
    }

    const isValidInput = /^[0-9]+\.?[0-9]*$/.test(value);

    const dotCount = value.split(".").length - 1;
    if (!isValidInput || dotCount > 1) {
      return;
    }

    const numericValue = Number(value);

    if (numericValue < 0) {
      return;
    }

    setSolanaAmount(value);
    debouncedInputChange(numericValue);
  };

  const handleAmountClick = (amount: number) => {
    if (!isActiveWallet) return;
    setSolanaAmount(amount);
    setSelectedAmountOption(amount);
  };

  const buyTokenTransaction = async () => {
    if (+phantomWalletSolanaBalance < (+solanaAmount || 0)) {
      showNotification({
        title: "Insufficient funds!",
        type: ToastNotificationType.Error,
        timeoutMs: 3000,
      });
      return;
    }
  };

  const sellTokenTransaction = async () => {
    if (!associatedAccounts || +associatedAccounts?.tokenCount! <= 0) {
      showNotification({
        title: "You have not bought this token yet!",
        type: ToastNotificationType.Error,
        timeoutMs: 3000,
      });
      return;
    }
    if (+associatedAccounts?.sol! < (+solanaAmount || 0)) {
      showNotification({
        title: "Insufficient funds!",
        type: ToastNotificationType.Error,
        timeoutMs: 3000,
      });
      return;
    }
  };

  const handleTransaction = async () => {
    try {
      const dataSavedTransaction = await getDataSavedTransaction();

      if (!dataSavedTransaction) {
        setIsOpened(true);
      } else {
        showNotification({
          title: "Error! The previous transaction is not completed!",
          type: ToastNotificationType.Error,
          timeoutMs: 3000,
        });
      }
    } catch (error) {
      console.error("Error fetching saved transaction:", error);
      showNotification({
        title: "Error! Something went wrong when checking the saved transaction!",
        type: ToastNotificationType.Error,
        timeoutMs: 3000,
      });
    }
    if (+solanaAmount <= 0) {
      showNotification({
        title: "Enter the correct value!",
        type: ToastNotificationType.Error,
        timeoutMs: 3000,
      });
      return;
    }
    if (isActiveWallet) {
      if (+phantomWalletSolanaBalance <= 0) {
        showNotification({
          title: "Insufficient funds!",
          type: ToastNotificationType.Error,
          timeoutMs: 3000,
        });
        return;
      }
      if (activeTradeType === TradeType.BUY) buyTokenTransaction();
      if (activeTradeType === TradeType.SELL) sellTokenTransaction();
    } else {
      showNotification({
        title: "Connect your wallet!",
        type: ToastNotificationType.Error,
      });
    }
  };
  const getActualCountToken = useCallback(
    (value: number | null = null) => {
      const amount = value ? value : +solanaAmount;
      const data = {
        priceWithFee: currentTokenPriceData?.priceWithFee || 0,
        priceWithoutFee: currentTokenPriceData?.priceWithoutFee || 0,
        amount: amount || 0,
      };
      console.log("activeTradeType", activeTradeType);

      if (activeTradeType === TradeType.BUY) {
        dispatch(fetchCountTokensAndPriceAction(data));
      }
      if (activeTradeType === TradeType.SELL) {
        dispatch(fetchCountTokensForSellAction(data));
      }
    },
    [selectedAmountOption, currentTokenPriceData, activeTradeType],
  );

  useEffect(() => {
    const countOftokens =
      activeTradeType === TradeType.BUY
        ? currentCountTokensAndPrice?.tokensToRelease
        : currentCountTokensForSell?.totalTokens;

    setMessage(
      activeTradeType === TradeType.BUY ? `You will receive ≈ ${countOftokens}` : `You will sell ≈ ${countOftokens}`,
    );
  }, [activeTradeType, currentCountTokensAndPrice, currentCountTokensForSell]);

  useEffect(() => {
    getActualCountToken();
  }, [selectedAmountOption, activeTradeType, solanaAmount]);

  useEffect(() => {
    if (!isActiveWallet || +phantomWalletSolanaBalance <= 0) setIsDisableTransaction(true);
  }, [isActiveWallet, phantomWalletSolanaBalance]);

  return (
    <div>
      <SolanaAmountField
        handleInputChange={handleInputChange}
        solanaAmount={solanaAmount}
        activeTradeType={activeTradeType}
      />
      <div className="flex justify-start space-x-[6px] overflow-x-auto overflow-y-hidden  scrollbar-hide pt-2">
        {amountOptions.map((amount) => (
          <AmountOption
            key={amount}
            amount={amount}
            selectedAmountOption={selectedAmountOption}
            onClick={handleAmountClick}
          />
        ))}
      </div>
      <p className="pt-4 pb-3 text-center text-[12px] text-lavenderGray">
        {message} ${token?.ticker}
      </p>
      <Button
        handleClick={handleTransaction}
        text={activeTradeType}
        style={isDisableTransaction ? "grey" : setBackgroundTradeType(activeTradeType)}
      />
      <ConfirmPurchase
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        solanaAmount={+solanaAmount}
        activeTradeType={activeTradeType}
      />
    </div>
  );
};

export default TransactionControl;
