import { useEffect, useLayoutEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";
import Title from "../../components/ui/Title";
import NotesList from "../../components/Pumpadur/Notes/NotesList";
import NoteForm from "../../components/Pumpadur/Notes/NoteForm";
import { useParams } from "react-router-dom";
import { fetchNotesForTokenAction } from "../../store/token/actions/fetchNotesForTokenAction";
import { useSelector } from "react-redux";
import { communityNotesSelector } from "../../store/token/tokensReducer";
import ReactDOM from "react-dom";

const CommunityNotesPage = () => {
  const { tokenId } = useParams();

  const communityNotes = useSelector(communityNotesSelector);

  const [inputNote, setInputNote] = useState<string>("");

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    if (tokenId) dispatch(fetchNotesForTokenAction(tokenId));
    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pb-[100px]">
      <Title text="Community notes" />
      <NotesList communityNotes={communityNotes} />

      {ReactDOM.createPortal(
        <NoteForm tokenId={tokenId!} value={inputNote} handleChange={setInputNote} />,
        document.body,
      )}
    </div>
  );
};

export default CommunityNotesPage;
