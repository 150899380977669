import { useState, useCallback } from "react";
import { clusterApiUrl, Connection, PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import nacl from "tweetnacl";
import bs58 from "bs58";
import { Buffer } from "buffer";
import { buildUrl } from "../helpers/buildUrl";
import { encryptPayload } from "../helpers/encryptionHelpers";
import { useTelegramApp } from "./useTelegramApp";
import { ACCESS_TOKEN_KEY } from "../constants/auth";
import { useSelector } from "react-redux";
import {
  associatedAccountsSelector,
  currentCountTokensAndPriceSelector,
  currentCountTokensForSellSelector,
  currentTokenSelector,
} from "../store/token/tokensReducer";
import {
  cryptoProfilePublicKeySelector,
  dappPublicKeySelector,
  dappSecretKeySelector,
  sessionKeySelector,
  sharedSecretSelector,
} from "../store/cryptoProfile/cryptoProfileReducer";
import { sellToken } from "../api/solana/sellTokem";

const NETWORK = clusterApiUrl(import.meta.env.VITE_SOLANA_CLUSTER);
global.Buffer = global.Buffer || Buffer;

// interface UseTransactionProps {
//   phantomWalletPublicKey: PublicKey;
//   dappKeyPair: nacl.BoxKeyPair;
//   sharedSecret: Uint8Array;
//   session: string;
//   solanaAmount: number;
// }
const onSignAndSendTransactionRedirectLink = "onSignAndSendTransaction";

export const useTransaction = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const connection = new Connection(NETWORK);

  const currentCountTokensAndPrice = useSelector(currentCountTokensAndPriceSelector);
  const associatedAccounts = useSelector(associatedAccountsSelector);
  const currentCountTokensForSell = useSelector(currentCountTokensForSellSelector);
  const phantomWalletPublicKeyString = useSelector(cryptoProfilePublicKeySelector);
  let phantomWalletPublicKey: PublicKey | null = phantomWalletPublicKeyString
    ? new PublicKey(phantomWalletPublicKeyString)
    : null;
  const token = useSelector(currentTokenSelector);
  const dappSecretKey = useSelector(dappSecretKeySelector);
  const dappPublicKey = useSelector(dappPublicKeySelector);
  const session = useSelector(sessionKeySelector);
  const sharedSecret = bs58.decode(useSelector(sharedSecretSelector));
  const publicKeyDapp = dappPublicKey ? bs58.decode(dappPublicKey) : null;
  const secretKeyDapp = dappSecretKey ? bs58.decode(dappSecretKey) : null;
  const dappKeyPair: nacl.BoxKeyPair | null =
    publicKeyDapp && secretKeyDapp
      ? {
          publicKey: publicKeyDapp,
          secretKey: secretKeyDapp,
        }
      : null;
  const tgApp = useTelegramApp();

  // Створення транзакції
  const createTransferTransaction = useCallback(async () => {
    if (!phantomWalletPublicKey) throw new Error("missing public key from user");

    const sumForPay = currentCountTokensAndPrice?.sumForPay || 0;
    const lamports = Math.round(sumForPay * 1_000_000_000);
    console.log("solanaAmount", sumForPay || 0);
    console.log("lamports", lamports);
    console.log("type of lamports", typeof lamports);

    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: phantomWalletPublicKey,
        toPubkey: new PublicKey("4xb6aFGuiqVn7zDTCvd2KpnAntey3B2x8EcaAG794v4C"),
        lamports: lamports,
      }),
    );
    transaction.feePayer = phantomWalletPublicKey;

    const anyTransaction: any = transaction;
    anyTransaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

    return transaction;
  }, [currentCountTokensAndPrice]);

  // Підписання та відправка транзакції
  const signAndSendTransaction = useCallback(async () => {
    try {
      setIsLoading(true);
      const transaction = await createTransferTransaction();

      const serializedTransaction = transaction.serialize({
        requireAllSignatures: false,
      });

      const payload = {
        session,
        transaction: bs58.encode(serializedTransaction),
      };

      const [nonce, encryptedPayload] = encryptPayload(payload, sharedSecret);

      const token = localStorage.getItem(ACCESS_TOKEN_KEY) || "";

      if (!dappKeyPair) throw new Error("missing dappKeyPair from user");

      const params = new URLSearchParams({
        dapp_encryption_public_key: bs58.encode(dappKeyPair.publicKey),
        nonce: bs58.encode(nonce),
        redirect_link: `${import.meta.env.VITE_PHANTOM_SITE_URL}${onSignAndSendTransactionRedirectLink}?trade=buy&token=${token}`,
        payload: bs58.encode(encryptedPayload),
      });

      const url = buildUrl("signTransaction", params, true);
      console.log("url", url);

      tgApp.openLink(url);
      tgApp.close();
    } catch (err) {
      setError(err as Error);
      console.error("Error during sign and send transaction:", err);
    } finally {
      setIsLoading(false);
    }
  }, [createTransferTransaction]);

  const sellTokenTransaction = useCallback(async () => {
    if (!token) throw new Error("missing public key from user");
    if (!associatedAccounts) throw new Error("You have not bought this token yet!");
    const fromTokenAccount = new PublicKey(associatedAccounts?.tokenAccount!);
    const toTokenAccount = new PublicKey(token.mintAccount!);

    const userPublicKey = new PublicKey(phantomWalletPublicKey!);
    const amount = currentCountTokensForSell?.totalTokens || 0;
    try {
      setIsLoading(true);

      const transaction = await sellToken(connection, fromTokenAccount, toTokenAccount, userPublicKey, amount);

      const serializedTransaction = transaction.serialize({
        requireAllSignatures: false,
      });

      const payload = {
        session,
        transaction: bs58.encode(serializedTransaction),
      };

      const [nonce, encryptedPayload] = encryptPayload(payload, sharedSecret);

      const token = localStorage.getItem(ACCESS_TOKEN_KEY) || "";

      if (!dappKeyPair) throw new Error("missing dappKeyPair from user");

      const params = new URLSearchParams({
        dapp_encryption_public_key: bs58.encode(dappKeyPair.publicKey),
        nonce: bs58.encode(nonce),
        redirect_link: `${import.meta.env.VITE_PHANTOM_SITE_URL}${onSignAndSendTransactionRedirectLink}?trade=sell&token=${token}`,
        payload: bs58.encode(encryptedPayload),
      });

      const url = buildUrl("signTransaction", params, true);
      console.log("url", url);

      tgApp.openLink(url);
      tgApp.close();
    } catch (err) {
      setError(err as Error);
      console.error("Error during sign and send transaction:", err);
    } finally {
      setIsLoading(false);
    }
  }, [associatedAccounts, token, phantomWalletPublicKey, currentCountTokensForSell]);

  return {
    isLoading,
    error,
    createTransferTransaction,
    signAndSendTransaction,
    sellTokenTransaction,
  };
};
