import { useSelector } from "react-redux";
import Input from "../../../ui/Input";
import {
  cryptoProfileBalanceSelector,
  isActiveWalletSelector,
} from "../../../../store/cryptoProfile/cryptoProfileReducer";
import { useEffect, useState } from "react";
import { TradeType } from "./FilterTrade";
import { associatedAccountsSelector, currentTokenSelector } from "../../../../store/token/tokensReducer";

interface SolanaAmountFieldProps {
  solanaAmount?: string | number;
  handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  activeTradeType: TradeType;
}

const SolanaAmountField = ({ handleInputChange, solanaAmount, activeTradeType }: SolanaAmountFieldProps) => {
  const [isDisableTransaction, setIsDisableTransaction] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const phantomWalletSolanaBalance = useSelector(cryptoProfileBalanceSelector);
  const associatedAccounts = useSelector(associatedAccountsSelector);
  const isActiveWallet = useSelector(isActiveWalletSelector);
  const { liquidity } = useSelector(currentTokenSelector)!;

  const balance = activeTradeType === TradeType.BUY ? phantomWalletSolanaBalance : associatedAccounts?.sol || 0;

  useEffect(() => {
    setErrorMessage(null);
    if (!isActiveWallet) {
      setIsDisableTransaction(true);
      // setErrorMessage("Connect your wallet!");
      return;
    }

    const amount = Number(solanaAmount);

    if (amount <= 0) {
      setErrorMessage("Enter the correct value!");
      return;
    }

    if (activeTradeType === TradeType.BUY) {
      if (+phantomWalletSolanaBalance <= 0 || +phantomWalletSolanaBalance < amount) {
        setErrorMessage("Insufficient funds!");
        return;
      }
    }

    if (activeTradeType === TradeType.SELL) {
      if (!associatedAccounts) {
        setErrorMessage("You have not bought this token yet!");
        return;
      }
      if (+phantomWalletSolanaBalance <= 0 || +associatedAccounts?.sol! < amount || amount > +liquidity) {
        setErrorMessage("Insufficient funds!");
        return;
      }
    }
  }, [isActiveWallet, phantomWalletSolanaBalance, solanaAmount, activeTradeType]);
  return (
    <>
      <div className="relative">
        <Input
          bgColor="rgba(0, 0, 0, 0.25)"
          value={solanaAmount}
          handleChange={handleInputChange}
          className="font-bold text-[20px] pr-[85px]"
          containerClassname="h-[56px]"
          type="text"
          error={!!errorMessage}
          readOnly={isDisableTransaction}
        />
        <div className="absolute bottom-3 right-[15px] z-10 leading-none text-right">
          <p className="uppercase font-semibold text-[16px]">SOL</p>
          <p className="text-xs font-medium text-[#95879e]">Balance: {balance}</p>
        </div>
      </div>
      {errorMessage && <p className="text-sm text-[#F54660] ">{errorMessage}</p>}
    </>
  );
};

export default SolanaAmountField;
