import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { GraphData, PriceData } from "../../../../api/tokens/types";
import { getHistoricalTokenPrice } from "../../../../api/tokens/getHistoricalTokenPrice";
import { useSelector } from "react-redux";
import { currentTokenSelector } from "../../../../store/token/tokensReducer";

type FormattedDate = { date: Date | null; price: number };
enum TimeGroup {
  "15m" = "0.15",
  "1H" = "1",
  "1D" = "24",
  "5D" = "120",
  "1M" = "720",
}

const TokenChart = () => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [data, setData] = useState<PriceData[]>([]);
  const [period, setPeriod] = useState<TimeGroup>(TimeGroup["1D"]);
  const [currentZoomState, setCurrentZoomState] = useState<d3.ZoomTransform | null>(null);
  const token = useSelector(currentTokenSelector);
  // const getTimeFormat = (period: TimeGroup) => {
  //   switch (period) {
  //     case TimeGroup["15m"]:
  //     case TimeGroup["1H"]:
  //       return d3.timeFormat("%H:%M"); // Формат "година:хвилина"
  //     case TimeGroup["1D"]:
  //     case TimeGroup["5D"]:
  //       return d3.timeFormat("%b %d"); // Формат "Місяць день"
  //     case TimeGroup["1M"]:
  //       return d3.timeFormat("%b %Y"); // Формат "Місяць рік"
  //     default:
  //       return d3.timeFormat("%Y"); // Рік
  //   }
  // };

  useEffect(() => {
    const data: GraphData = { tokenId: +token?.id!, time: +period };

    getHistoricalTokenPrice(data).then((data) => {
      setData(data);
    });
  }, []);

  useEffect(() => {
    if (!data) return;
    if (!svgRef.current) return;

    const svg = d3.select<SVGSVGElement, unknown>(svgRef.current);
    const svgNode = svg.node() as SVGSVGElement | null;
    const width = svgNode ? svgNode.getBoundingClientRect().width : 0;
    console.log("width", width);

    const height = 270;
    const margin = { top: 20, right: 60, bottom: 40, left: 0 }; //

    svg.attr("width", width).attr("height", height);

    // Форматування даних
    const parseTime = d3.utcParse("%Q");
    const formattedData: FormattedDate[] = data.map((d) => ({
      date: parseTime(d[0].toString()),
      price: d[1],
    }));

    // Масштаби
    const validData = formattedData.filter((d) => d.date !== null) as { date: Date; price: number }[];

    const extent = d3.extent(validData, (d) => d.date);

    const xDomain: [Date, Date] = extent[0] && extent[1] ? (extent as [Date, Date]) : [new Date(0), new Date()];

    const x = d3
      .scaleTime()
      .domain(xDomain)
      .range([margin.left, width - margin.right]);

    if (currentZoomState) {
      const newXScale = currentZoomState.rescaleX(x);
      x.domain(newXScale.domain()).range([margin.left - 50, width - margin.right]);
    }

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(validData, (d) => d.price) || 0])
      .range([height - margin.bottom, margin.top]);

    // Лінія
    const line = d3
      .line<{ date: Date; price: number }>()
      .x((d) => x(d.date))
      .y((d) => y(d.price))
      .curve(d3.curveCatmullRom.alpha(0.5));

    svg.selectAll("*").remove();

    // const formatDate: (date: Date) => string = d3.timeFormat(dateFormats[period]);

    //
    svg
      .append("g")
      .attr("transform", `translate(0,${height - margin.bottom + 10})`)
      .call(d3.axisBottom(x).ticks(5)) // Use the correct format based on period
      .attr("class", "chart-scales-text")
      .style("margin-top", "50px")
      .selectAll("path, line")
      .remove();

    svg
      .append("g")
      .attr("transform", `translate(${width - margin.right},0)`) // Розміщення праворуч
      .call(
        d3.axisRight(y).ticks(5).tickFormat(d3.format("$.2f")), //  $XXX.XX
      )
      .call((g) => g.select(".domain").remove())
      .call((g) =>
        g
          .selectAll(".tick line")
          .attr("stroke-opacity", 0.5)
          .attr("stroke-dasharray", "2,2")
          .attr("x2", -(width - margin.right)),
      )
      .call((g) => g.selectAll(".tick text").attr("x", 10).attr("dy", 2))
      .attr("class", "chart-scales-text");

    // Додавання лінії графіка

    //clipPath
    const clipPathId = "clip";
    svg
      .append("defs")
      .append("clipPath")
      .attr("id", clipPathId)
      .append("rect")
      .attr("x", margin.left)
      .attr("y", margin.top)
      .attr("width", width - margin.left - margin.right)
      .attr("height", height - margin.top - margin.bottom);

    svg
      .append("path")
      .datum(formattedData)
      .attr("fill", "none")
      .attr("stroke", "#45D700")
      .style("max-width", "100px")
      .attr("stroke-width", 2)
      .attr("d", (d) => line(d as any))
      .attr("clip-path", `url(#${clipPathId})`);

    // Додавання підказки

    // Лінії для підказки
    const focusLineX = svg
      .append("line")
      .attr("stroke", "#45D700")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", "4,4")
      .style("visibility", "hidden");

    const focusLineY = svg
      .append("line")
      .attr("stroke", "#45D700")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", "4,4")
      .style("visibility", "hidden");

    const tooltipGroup = svg.append("g").style("visibility", "hidden");

    // Прямокутник для фону підказки
    const tooltipRect = tooltipGroup
      .append("rect")
      .attr("fill", "#139602")
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("height", 30)
      .attr("width", 50);

    // Текст для підказки
    const tooltipText = tooltipGroup
      .append("text")
      .attr("x", 10)
      .attr("y", 20)
      .attr("fill", "#fff")
      .attr("class", "chart-tooltip-text");

    const focus = svg
      .append("g")
      .append("circle")
      .style("fill", "#ffff")
      .attr("stroke", "#45D700")
      .attr("stroke-width", 2)
      .attr("r", 6)
      .style("visibility", "hidden");

    const bisectDate = d3.bisector((d: FormattedDate) => d.date).left;

    svg
      .append("rect")
      .attr("width", width - margin.left - margin.right)
      .attr("height", height - margin.top - margin.bottom)
      .attr("x", margin.left)
      .attr("y", margin.top)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("mousemove", (event) => {
        const [xPos] = d3.pointer(event);
        const date = x.invert(xPos) as Date;
        const i = bisectDate(formattedData, date, 1);
        const d0 = formattedData[i - 1];
        const d1 = formattedData[i];

        // Перетворення дат у числовий формат (мс від 1970 року)
        const d = +date - +d0.date! > +d1.date! - +date ? d1 : d0;

        focusLineX
          .attr("x1", x(d.date!))
          .attr("x2", x(d.date!))
          .attr("y1", margin.top)
          .attr("y2", height - margin.bottom)
          .style("visibility", "visible");

        focusLineY
          .attr("x1", margin.left)
          .attr("x2", width - margin.right)
          .attr("y1", y(d.price))
          .attr("y2", y(d.price))
          .style("visibility", "visible");

        // Початкове позиціонування підказки
        let tooltipX = x(d.date!) + 5;
        let tooltipY = y(d.price) - 35;

        const tooltipWidth = tooltipText.node()!.getBBox().width + 20;
        if (tooltipX + tooltipWidth > width - margin.right) {
          tooltipX = x(d.date!) - tooltipWidth - 5;
        }

        if (tooltipY < margin.top) {
          tooltipY = y(d.price) + 15;
        }

        tooltipGroup.attr("transform", `translate(${tooltipX},${tooltipY})`).style("visibility", "visible");

        tooltipText.text(`$${d.price.toFixed(2)}`);

        const textWidth = tooltipText.node()!.getBBox().width + 20;
        tooltipRect.attr("width", textWidth);

        focus.attr("cx", x(d.date!)).attr("cy", y(d.price)).style("visibility", "visible");
      })

      .on("mouseleave", () => {
        tooltipGroup.style("visibility", "hidden");
        focus.style("visibility", "hidden");
        focusLineX.style("visibility", "hidden");
        focusLineY.style("visibility", "hidden");
      });

    const zoomBehavior: d3.ZoomBehavior<SVGSVGElement, unknown> = d3
      .zoom<SVGSVGElement, unknown>()
      .scaleExtent([0.5, 5])
      .translateExtent([
        [margin.left, margin.top],
        [width - margin.right, height - margin.bottom],
      ])
      .on("zoom", (event) => {
        const zoomState = event.transform;
        console.log(zoomState);

        setCurrentZoomState(zoomState);
      });

    svg.call(zoomBehavior);
  }, [data, currentZoomState, period]);

  const handlePeriodChange = (newPeriod: TimeGroup) => {
    const requestData: GraphData = { tokenId: +token?.id!, time: +newPeriod };
    setCurrentZoomState(null);

    if (period !== newPeriod) {
      setPeriod(newPeriod);
      getHistoricalTokenPrice(requestData).then((responseData) => {
        setData(responseData);
      });
    }
  };

  return (
    <>
      {/* <div className="w-full bg-black/50 p-[14px] rounded-lg mt-4">
        <img src={tokenChartIcon} alt="" />
      </div> */}
      <div className="w-full bg-black/50 rounded-lg mt-4 pt-[14px] px-[14px]">
        <div className="flex justify-between px-[14px]">
          {Object.entries(TimeGroup).map(([key, value]) => (
            <button
              key={value}
              onClick={() => handlePeriodChange(value)}
              className={`px-4 py-2 rounded-lg ${period === value ? "bg-[#32A575] text-white" : ""}`}
            >
              {key}
            </button>
          ))}
        </div>
        <svg ref={svgRef} className="w-full h-[270px]"></svg>
      </div>
    </>
  );
};

export default TokenChart;
