import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";
import UserSummary from "../../components/Pumpadur/Profile/UserSummary";
import CreatedTokensList from "../../components/Pumpadur/Profile/CreatedTokensList";
import TransactionList from "../../components/Pumpadur/Profile/Transaction/TransactionList";
import NftList from "../../components/Pumpadur/Profile/Nft/NftList";
import { useSelector } from "react-redux";
import { userIdSelector, userNameSelector } from "../../store/account/account";
import {
  cryptoProfileBalanceSelector,
  cryptoProfilePublicKeySelector,
  followersSelector,
  followingSelector,
  isActiveWalletSelector,
  nftSelector,
} from "../../store/cryptoProfile/cryptoProfileReducer";
import { Token, TokensBuyStatus } from "../../store/token/api/types";
import { myTokensSelector, myTransactionSelector } from "../../store/token/tokensReducer";
import { fetchMyTransactionListAction } from "../../store/token/actions/fetchMyTransactionListAction";
import ReferralSystem from "../../components/Common/ReferralSystem";

const ProfilePage = () => {
  const userName = useSelector(userNameSelector);
  const userId = useSelector(userIdSelector);
  const followers = useSelector(followersSelector);
  const following = useSelector(followingSelector);
  const phantomWalletBalance = useSelector(cryptoProfileBalanceSelector);
  const phantomWalletPublicKey = useSelector(cryptoProfilePublicKeySelector);

  const isActiveWallet = useSelector(isActiveWalletSelector);
  const myTransactions = useSelector(myTransactionSelector);
  const myNfts = useSelector(nftSelector);

  const dispatch = useAppDispatch();

  const [createdTokens, setCreatedTokens] = useState<Token[] | null>(null);
  const myTokens = useSelector(myTokensSelector);

  const startPram = `pumpadur/owner-profile/${userId}`;

  useEffect(() => {
    setCreatedTokens(myTokens?.filter((token) => token.status === TokensBuyStatus.CREATED));
  }, [myTokens]);

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    dispatch(fetchMyTransactionListAction());
    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, []);

  return (
    <div className="relative pb-[20px]">
      <UserSummary
        followers={followers}
        following={following}
        userId={userId!}
        userName={userName!}
        isActiveWallet={isActiveWallet}
        phantomWalletBalance={phantomWalletBalance}
        phantomWalletPublicKey={phantomWalletPublicKey}
        abilitytoRedirectToWalletManagement={true}
      />

      <div>
        <h2 className="text-center text-[12px] font-bold opacity-50 leading-none mt-5 mb-[14px]">Your Referral link</h2>
        <ReferralSystem text="Share" startParam={startPram} />
      </div>
      <NftList list={myNfts} />
      <CreatedTokensList createdTokens={createdTokens} />
      <TransactionList transactions={myTransactions} />
    </div>
  );
};

export default ProfilePage;
