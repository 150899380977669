import { useEffect, useRef } from "react";
import { CreateNote } from "../../../api/communityNotes/types";
import { useAppDispatch } from "../../../store/hooks";
import { createNoteAction } from "../../../store/token/actions/createNoteAction";
import { useVisualViewport } from "../../../hooks/useVisualViewport";
import React from "react";
import scrollLock from "scroll-lock";
// import { lockScroll, unlockScroll } from "@js4y/lock-scroll";

interface NoteFormProps {
  value: string;
  handleChange: (newValue: string) => void;
  tokenId: number | string;
}

const NoteForm = React.memo(({ handleChange, value, tokenId }: NoteFormProps) => {
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const isFocusedRef = useRef<boolean>(false);

  let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const { height: heightVisualViewport } = useVisualViewport();

  useEffect(() => {
    if (heightVisualViewport < window.innerHeight) {
      const currentPaddingBottom = window.innerHeight - heightVisualViewport;
      if (containerRef.current && isIOS) {
        containerRef.current.style.bottom = `${currentPaddingBottom}px`;
        containerRef.current.style.paddingBottom = `20px`;
      }
    } else {
      if (containerRef.current && isIOS) {
        containerRef.current.style.bottom = `0px`;
        containerRef.current.style.paddingBottom = `20px`;
      }
    }
  }, [heightVisualViewport]);

  const scrollHandler = () => {
    console.log("scrollHandler isFocused", isFocusedRef.current);
    if (isFocusedRef.current) {
      // scrollLock.disablePageScroll();
      // lockScroll();
      console.log("block scroll");
    } else {
      // scrollLock.enablePageScroll();
      // unlockScroll();
      console.log("unblock scroll");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
      scrollLock.enablePageScroll();
    };
  }, []);
  console.log("isFocused", isFocusedRef.current);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.value);
  };

  const handleSendNote = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data: CreateNote = {
      tokenId: +tokenId,
      message: value,
    };

    dispatch(createNoteAction(data)).finally(() => handleChange(""));
  };

  const handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    isFocusedRef.current = true;
    e.preventDefault();

    if (containerRef.current && isIOS) {
      containerRef.current.style.paddingBottom = `${window.innerHeight / 2 + 20}px`;
      containerRef.current.style.left = `-100vw`;
    }
  };

  const handleBlur = () => {
    isFocusedRef.current = false;
    scrollLock.enablePageScroll();
    if (containerRef.current && isIOS) {
      containerRef.current.style.paddingBottom = `20px`;
      containerRef.current.style.left = `0px`;
    }
  };
  console.log("add setTimeout");

  return (
    <div
      ref={containerRef}
      className="w-full note-form fixed bottom-0 left-0 right-0 z-50 bg-black/50 backdrop-blur-xl px-4 pt-3 pb-5 gap-2"
    >
      <form onSubmit={handleSendNote} className="flex gap-2">
        <input
          type="text"
          ref={inputRef}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleInputChange}
          className="appearance-none w-full h-[40px] px-4 outline-none rounded-3xl truncate backdrop-blur-xl bg-[#d9d9d9]/15"
        />
        <button
          type="submit"
          className="rounded-3xl px-4"
          disabled={value.length <= 2}
          style={{
            background:
              value.length <= 2
                ? "rgba(217, 217, 217, 0.15)"
                : "radial-gradient(406.24% 212.5% at 0% -44.64%, rgb(229, 67, 255) 0%, rgb(131, 45, 191) 100%)",
          }}
        >
          Send
        </button>
      </form>
    </div>
  );
});

export default NoteForm;
