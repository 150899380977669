import nftImage from "../../../../assets/img/nft.png";
import { Nft } from "../../../../store/cryptoProfile/types";

const NftItem = ({ nft }: { nft: Nft }) => {
  const { body, limit, rakeback, rarity, name } = nft;
  return (
    <div className="bg-black/25 rounded-2xl text-[12px] w-full h-full mb-4">
      <img src={nftImage} alt="nft image" className="w-full rounded-t-2xl  h-[21vh] object-cover " />

      <div className="px-4 py-3">
        <h3 className="font-bold">{name}</h3>
        <p>
          <span className="text-medGrey pr-1">Limits:</span>
          <span>{limit}</span>
        </p>
        <p>
          <span className="text-medGrey pr-1">Rarity:</span>
          <span>{rarity}</span>
        </p>
        <p>
          <span className="text-medGrey pr-1">Body:</span>
          <span>{body}</span>
        </p>
        <p>
          <span className="text-medGrey pr-1">Rakeback:</span>
          <span>{rakeback}%</span>
        </p>
      </div>
    </div>
  );
};

export default NftItem;
