import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { fetchTokenInfoAction } from "../../store/token/actions/fetchTokenInfoAction";
import TokenHeader from "../../components/Pumpadur/Token/TokenTrade/TokenHeader";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";
import TokenChart from "../../components/Pumpadur/Token/TokenTrade/TokenChart";
import TradeActions from "../../components/Pumpadur/Token/TokenTrade/TradeActions";
import { useSelector } from "react-redux";
import { currentTokenSelector, isPendingSelector } from "../../store/token/tokensReducer";
import TransactionSummary from "../../components/Pumpadur/Token/TokenTrade/Transaction/TransactionSummary";
import TransactionList from "../../components/Pumpadur/Token/TokenTrade/Transaction/TransactionList";
import LastNote from "../../components/Pumpadur/Notes/LastNote";
import { fetchLastNoteForTokenAction } from "../../store/token/actions/fetchLastNoteForTokenAction";
import { fetchTokenPriceDataAction } from "../../store/token/actions/fetchTokenPriceDataAction";

const TokenTradePage = () => {
  const { tokenId } = useParams();

  const dispatch = useAppDispatch();
  const isPending = useSelector(isPendingSelector);
  const token = useSelector(currentTokenSelector);

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    dispatch(fetchTokenInfoAction(tokenId!));
    dispatch(fetchLastNoteForTokenAction(tokenId!));

    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, [tokenId]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchTokenPriceDataAction(tokenId!));
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [tokenId, dispatch]);

  return (
    <>
      {!isPending && token && (
        <div className="pb-5">
          <TokenHeader />
          <TokenChart />
          <TradeActions />
          <TransactionSummary />
          <div className="pt-[4vh]">
            <LastNote />
          </div>
          <TransactionList />
        </div>
      )}
    </>
  );
};

export default TokenTradePage;
