import { Outlet } from "react-router-dom";

import { BackButton } from "@twa-dev/sdk/react";
import { useAppDispatch } from "./store/hooks";
import BottomMenu from "./components/BottomMenu/BottomMenu";
import { useAuth } from "./hooks/useAuth.ts";
import { AppToastNotifications } from "./components/AppToastNotifications.tsx";
import { useEffect, useState } from "react";
import { useTelegramApp } from "./hooks/useTelegramApp.ts";
import { AppBg } from "./components/ui/AppBg.tsx";
import { ScreenLayout } from "./components/ui/ScreenLayout.tsx";
import { useNavigate } from "react-router-dom";
import { fetchInitialClickerDataAction } from "./store/clicker/actions/fetchInitialClickerDataAction.ts";
import { DataLayerEventNames, useDataLayer } from "./hooks/useDataLayer.ts";
import ReactDOM from "react-dom";
import { fetchUserAnalyticsDataAction } from "./store/account/actions/fetchUserAnalyticsDataAction.ts";
import { init } from "@amplitude/analytics-browser";
import { fetchUserInfoAction } from "./store/account/actions/fetchUserInfoAction.ts";
import { fetchRewardForReferralsAction } from "./store/account/actions/fetchRewardForReferralsAction.ts";
import {
  curentDateSelector,
  isShowSpecialPopupSelector,
  lastDailyRewardDateSelector,
  resetDailyStreak,
  rewardForReferralsSelector,
} from "./store/account/account.tsx";
import { useSelector } from "react-redux";
import { LeagueTransition } from "./components/Clicker/LeagueTransition.tsx";
import { fetchCardsListAction } from "./store/cards/actions/fetchCardsListAction.ts";
import { fetchPurchasedCardsListAction } from "./store/cards/actions/fetchPurchasedCardsListAction.ts";
import { fetchInvitesDataAction } from "./store/friends/actions/fetchInvitesDataAction.ts";
import { fetchComboDataAction } from "./store/cards/actions/fetchComboDataAction.ts";
import { PopupCompensation } from "./components/Common/PopupCompensation.tsx";
import { fetchLeaguesListAction } from "./store/leagues/actions/fetchLeaguesListAction.ts";
import { fetchDailyRewardsAction } from "./store/cards/actions/fetchDailyRewards.ts";
import { fetchCryptoProfileAction } from "./store/cryptoProfile/actions/fetchCryptoProfileAction.ts";
import { PopupCardReward } from "./components/DailyReward/PopupCardReward/PopupCardReward.tsx";
import { fetchUserProfileAction } from "./store/account/actions/fetchUserProfileAction.ts";
import { checkInvites } from "./api/invites/checkInvites.ts";
import LimitNftPopUp from "./components/Pumpadur/MintAndManageNFT/LimitedNft.tsx";

export default function App() {
  const { trackEvent } = useDataLayer();
  const dispatch = useAppDispatch();
  const telegramApp = useTelegramApp();
  const [isAppLoading, setIsAppLoading] = useState(true);
  const rewardForReferrals = useSelector(rewardForReferralsSelector);
  const prevDayReward = useSelector(lastDailyRewardDateSelector);
  const isShowSpecialPopup = useSelector(isShowSpecialPopupSelector);
  const currentStartDate = useSelector(curentDateSelector);
  const [prevDayRewardLoaded, setPrevDayRewardLoaded] = useState(false);
  const [openNftPopUP, setOpenNftPopUP] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (isShowSpecialPopup) {
      setOpenNftPopUP(true);
    }
  }, [isShowSpecialPopup]);

  useEffect(() => {
    if (currentStartDate != "" && !prevDayRewardLoaded) {
      setPrevDayRewardLoaded(true);
    }
  }, [prevDayReward, currentStartDate]);

  useEffect(() => {
    if (!prevDayRewardLoaded) {
      return;
    }
    const updateState = async () => {
      const lastRewardDate = prevDayReward ? String(prevDayReward).split("T")[0] : null;
      let daysDifference = null;
      if (lastRewardDate) {
        const diffTime = Date.parse(currentStartDate) - Date.parse(lastRewardDate);
        daysDifference = diffTime / (1000 * 60 * 60 * 24);
      }
      if (prevDayReward === null) {
        navigate("/daily-reward");
      } else if (daysDifference === 1) {
        navigate("/daily-reward");
      } else if (daysDifference && daysDifference > 1) {
        dispatch(resetDailyStreak());
        navigate("/daily-reward");
      }
    };
    updateState();
  }, [prevDayRewardLoaded]);

  useEffect(() => {
    telegramApp.init();
    const amplitudeApiKey = import.meta.env.VITE_AMPLITUDE_PROJECT_API_KEY;

    init(amplitudeApiKey, {
      defaultTracking: false,
    });

    trackEvent(DataLayerEventNames.sessionStart);
  }, []);

  useEffect(() => {
    if (rewardForReferrals !== 0) {
      console.log(rewardForReferrals);
      navigate("/daily-earning");
    }
  }, [rewardForReferrals]);

  // const confirmTransaction = async (signature: string) => {
  //   try {
  //     const dataSavedTransaction = await getDataSavedTransaction();

  //     if (dataSavedTransaction) {
  //       const buyTokenData = {
  //         amount: +dataSavedTransaction.amount,
  //         mintAddress: dataSavedTransaction.mintAddress,
  //         tokenId: dataSavedTransaction.tokenId,
  //         tokenAccount: dataSavedTransaction.tokenAccount,
  //         signature: signature,
  //       };

  //       await postBuyToken(buyTokenData).then(() => {
  //         navigate(`/pumpadur/tokens/${dataSavedTransaction.tokenId}`);
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error getting transaction:", error);
  //   }
  // };

  useAuth({
    onLoginSuccess() {
      const { start_param, user } = telegramApp.initDataUnsafe || {};
      console.log(telegramApp.initDataUnsafe);
      console.log("START_PARAM", { start_param, user });

      if (start_param && user && start_param.includes("friend")) {
        checkInvites({ start_param, user }).catch();
      }
      // else if (start_param && start_param.includes("signature")) {
      //   const signature = start_param.replace(/signature/i, "");
      //   console.log("signature", signature);
      //   if (signature) {
      //     confirmTransaction(signature);
      //   }
      // }
      else if (start_param && start_param.includes("path")) {
        const path = atob(start_param.replace(/path=/i, ""));
        console.log("path", path);
        navigate(path);
      }

      dispatch(fetchLeaguesListAction());
      dispatch(fetchUserInfoAction());
      dispatch(fetchRewardForReferralsAction());
      dispatch(fetchCardsListAction());
      dispatch(fetchDailyRewardsAction());
      dispatch(fetchPurchasedCardsListAction());
      dispatch(fetchInvitesDataAction());
      dispatch(fetchComboDataAction());
      dispatch(fetchCryptoProfileAction());
      dispatch(fetchUserProfileAction());

      Promise.all([dispatch(fetchInitialClickerDataAction()), dispatch(fetchUserAnalyticsDataAction())]).then(
        ([payload]) => {
          if (fetchInitialClickerDataAction.fulfilled.match(payload)) {
            const isDemo = payload.payload?.dailyQuest?.quest.isDemo;
            const isDemoAlreadyStarted =
              payload.payload?.dailyQuest?.quest?.portfolios?.some((portfolio) =>
                portfolio.coins.some((coin) => coin.progress !== 0),
              ) ?? false;

            if (isDemo && !isDemoAlreadyStarted) {
              navigate("/onboarding/tap-and-earn-coins");
            }
          }

          setIsAppLoading(false);
          setOpenNftPopUP(true);

          setTimeout(() => {
            document.getElementById("app-loading-screen")?.remove();
          }, 500);
        },
      );
    },
  });

  const pathsWithBackButton = [
    "/clicker/boosts",
    "/frens-earning",
    "/daily-earning",
    "/selection-exchange",
    "/profit-per-hour",
    "/leagues-rewards-page",
    "/exchange-registration",
    "/pumpadur/settings",
    "/pumpadur/tokens",
    "/content-challenge",
    "/pumpadur/create-token",
    "/content-challenge",
    "/pumpadur/profile",
  ];

  const isDynamicMatch = (path: string, dynamicPaths: string[]) => {
    return dynamicPaths.some((dynamicPath) => path.startsWith(dynamicPath));
  };

  const showBackButton =
    pathsWithBackButton.includes(window.location.pathname) ||
    isDynamicMatch(window.location.pathname, ["/pumpadur/tokens"]);

  return (
    <>
      <ScreenLayout className="relative flex flex-col select-none min-h-screen main-container text-sm ">
        {/* <DailyReward/> */}
        <PopupCardReward />
        <LimitNftPopUp isOpened={openNftPopUP} onClose={() => setOpenNftPopUP(false)} />
        {showBackButton && <BackButton />}
        {!isAppLoading ? (
          <div className="relative z-40 flex-1 flex flex-col">
            <div className="flex-1 flex flex-col">
              <Outlet />
              {ReactDOM.createPortal(<BottomMenu />, document.body)}
            </div>
          </div>
        ) : null}

        <AppBg />
        <LeagueTransition />

        <PopupCompensation />
      </ScreenLayout>
      <AppToastNotifications />
    </>
  );
}
