import { useEffect, useState } from "react";
import { TradeType, setBackgroundTradeType } from "../FilterTrade";
import { GraphData, StatiscticsData } from "../../../../../api/tokens/types";
import { useSelector } from "react-redux";
import { currentTokenSelector } from "../../../../../store/token/tokensReducer";
import { getHistoricalTokenStatistics } from "../../../../../api/tokens/getHistoricalTokenStatistics";

interface Item {
  totalTitle: string;
  buyTitle: string;
  sellTitle: string;
  totalValue: number;
  buyValue: number;
  sellValue: number;
}
enum PeriodEnum {
  "5m" = 0.05,
  "15m" = 0.15,
  "1h" = 1,
  "24h" = 24,
}

const TransactionSummary = () => {
  const [data, setData] = useState<Item[]>();
  const token = useSelector(currentTokenSelector);
  const [period, setPeriod] = useState<PeriodEnum>(PeriodEnum["5m"]);
  const transactionHeaders = [
    { totalTitle: "TXNS", buyTitle: "BUYS", sellTitle: "SELLS" },
    { totalTitle: "VOLUME", buyTitle: "BUY VOL", sellTitle: "SELL VOL" },
    { totalTitle: "MAKERS", buyTitle: "BUYERS", sellTitle: "SELLERS" },
  ];
  const percentageChanges = [
    { period: PeriodEnum["5m"], percentage: 0.0 },
    { period: PeriodEnum["15m"], percentage: 3.18 },
    { period: PeriodEnum["1h"], percentage: -5.79 },
    { period: PeriodEnum["24h"], percentage: -2.75 },
  ];

  const fetchStatisticsData = (data: GraphData) => {
    getHistoricalTokenStatistics(data).then((data) => {
      console.log("StatisticsData", data);

      const statisticsData = formatToStatisticsData(data);
      console.log("formatToStatisticsData", statisticsData);

      setData(statisticsData);
    });
  };

  useEffect(() => {
    const data: GraphData = { tokenId: +token?.id!, time: +period };
    fetchStatisticsData(data);
  }, []);

  const changePeriod = (period: PeriodEnum) => {
    console.log("changePeriod", period);
    setPeriod(period);
    const data: GraphData = { tokenId: +token?.id!, time: +period };
    fetchStatisticsData(data);
  };

  const formatToStatisticsData = (apiData: StatiscticsData): Item[] => {
    return [
      {
        ...transactionHeaders[0], // TXNS
        totalValue: apiData.txns,
        buyValue: apiData.buy,
        sellValue: apiData.sell,
      },
      {
        ...transactionHeaders[1], // VOLUME
        totalValue: apiData.volume,
        buyValue: apiData.buyvol,
        sellValue: apiData.sellvol,
      },
      {
        ...transactionHeaders[2], // MAKERS
        totalValue: apiData.makers,
        buyValue: apiData.buyers,
        sellValue: apiData.sellers,
      },
    ];
  };

  return (
    <>
      <PercentageChangeBlock changes={percentageChanges} changePeriod={changePeriod} activePeriod={period} />
      <div className="pt-[14px]">{data && data.map((item, index) => <Item item={item} key={index} />)}</div>
    </>
  );
};

const Item = ({ item }: { item: Item }) => {
  const { buyTitle, buyValue, sellTitle, sellValue, totalTitle, totalValue } = item;
  return (
    <div className="flex gap-[7vw] leading-none text-[12px] py-4 border-solid border-b-[2px] border-white/10">
      <div className="flex flex-col gap-[6px] w-[52px] min-w-[52px] ">
        <p className="text-lavenderGray font-semibold">{totalTitle}</p>
        <p className="font-bold">{Math.round(totalValue)}</p>
      </div>
      <div className="w-full flex flex-col items-stretch">
        <div className="flex justify-between mb-2">
          <div>
            <span className="text-lavenderGray font-semibold pr-1">{buyTitle}</span>
            <span className="font-bold">{Math.round(buyValue)}</span>
          </div>
          <div>
            <span className="text-lavenderGray font-semibold pr-1">{sellTitle}</span>
            <span className="font-bold">{Math.round(sellValue)}</span>
          </div>
        </div>
        <div>
          <ProgressLine countBuys={buyValue} countSells={sellValue} />
        </div>
      </div>
    </div>
  );
};

const ProgressLine = ({ countBuys, countSells }: { countBuys: number; countSells: number }) => {
  const amount = countBuys + countSells;
  const buysPercentage = (countBuys * 100) / amount;
  const sellsPercentage = (countSells * 100) / amount;
  return (
    <div className={`flex gap-[1px] h-[8px] rounded-full w-full   p-[2px] bg-[#FFFFFF40]  items-center`}>
      <div className={`relative h-[6px] w-full rounded-full  flex`}>
        <div
          className="rounded-l-full h-[6px]"
          style={{
            width: `${buysPercentage}%`,
            background: setBackgroundTradeType(TradeType.BUY),
          }}
        ></div>
        <div
          className="rounded-r-full h-[6px]"
          style={{
            width: `${sellsPercentage}%`,
            background: setBackgroundTradeType(TradeType.SELL),
          }}
        ></div>
      </div>
    </div>
  );
};

interface PercentageChange {
  period: PeriodEnum;
  percentage: number;
}

interface PercentageChangeBlockProps {
  changes: PercentageChange[];
  changePeriod: (period: PeriodEnum) => void;
  activePeriod: PeriodEnum;
}

const PercentageChangeBlock: React.FC<PercentageChangeBlockProps> = ({ changes, changePeriod, activePeriod }) => {
  const getColor = (percentage: number) => {
    if (percentage >= 0) return "text-coralRed";
    if (percentage < 0) return "text-seaGreen";
  };

  const getPeriodKey = (value: PeriodEnum) => {
    return Object.keys(PeriodEnum).find((key) => PeriodEnum[key as keyof typeof PeriodEnum] === value);
  };

  return (
    <div className="w-full rounded-2xl bg-black/25 uppercase mt-3 py-3 leading-none">
      <ul className="flex justify-center items-center text-center">
        {changes.map(({ period, percentage }, index) => (
          <div className="relative">
            <li
              onClick={() => {
                changePeriod(period);
              }}
              key={period}
              className={`relative z-20 flex flex-col justify-between gap-[14px] px-[4.5vw] ${index < changes.length - 1 ? "border-solid border-r-[2px] border-white/10 " : ""} `}
            >
              <span className="font-semibold text-[12px]">{getPeriodKey(period)}</span>
              <span className={`${getColor(percentage)} font-bold`}>{percentage.toFixed(2)}%</span>
            </li>
            {activePeriod === period && (
              <p className="size-full -top-2 h-[140%] rounded-xl absolute bg-white/20  z-0"></p>
            )}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default TransactionSummary;
