import { useRef } from "react";

const useFocusHandler = <T extends HTMLTextAreaElement | HTMLInputElement>(paddingBottom: number, isIOS: boolean) => {
  const inputRef = useRef<T>(null);

  const handleFocus = (e: React.FocusEvent<T>) => {
    if (isIOS) {
      e.preventDefault();

      const container = document.querySelector(".main-container") as HTMLElement;
      if (container) {
        container.style.paddingBottom = `${paddingBottom}px`;
      }

      if (inputRef.current) {
        const rect = inputRef.current.getBoundingClientRect();
        window.scrollTo({
          top: rect.top + window.pageYOffset - paddingBottom,
          behavior: "smooth",
        });
      }
    }
  };

  const handleBlur = () => {
    if (isIOS) {
      const container = document.querySelector(".main-container") as HTMLElement;
      if (container) {
        container.style.paddingBottom = "0px";
        window.scrollTo(0, 0);
      }
    }
  };

  return { inputRef, handleFocus, handleBlur };
};

export default useFocusHandler;
